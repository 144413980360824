import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

const Threepics = styled.div `
grid-column:3/5;
grid-row:2/7;
display: flex;
flex-flow: column nowrap;
justify-content: space-between;
grid-gap:1em;
padding: 0 1em;
` 

export default Threepics