import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const ButtonMailto = ({ mailto, title, icon} ) => {

    
    return (
        
        <Link css={{
            textDecoration:'none',
            display:'flex',
            justifyContent:'center',
            
        }}   
            to='#'
            onClick={(e) => {
                window.location = mailto;
                e.preventDefault();
            }}
            title={title}
        >
        
          <FontAwesomeIcon css={{
            color:'yellow',
          }} 
         icon={icon}
           size="2x" />  
        </Link>
    );
};

export default ButtonMailto;