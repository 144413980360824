import React, { useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from '@emotion/styled';
import { MdClose } from 'react-icons/md';
import { Link } from 'gatsby';


const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  z-index:10;
`;

const ModalWrapper = styled.div`
  width: 66vw;
  height: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 100;
  border-radius: 10px;
`;

const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;

  p {
    margin-bottom: 1rem;
  }

  button {
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none;
  }
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

export const Modal = ({ showModal, setShowModal }) => {
  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`
  });

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
        console.log('I pressed');
      }
    },
    [setShowModal, showModal]
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );

  return (
    <>
      {showModal ? (
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalImg  alt='camera' />
              <ModalContent>
                
              <h1>
Tofu für Freunde            </h1>
          
          
          <p>Kochexperimente, kultursoziologische Arbeit, kulinarische Feldforschung
  Wir sind das limeriki Kollektiv aus Leipzig und produzieren in Handarbeit Tofu und Okarabällchen.</p>,
           

        <p>Tofu für Freunde - was als Kochexperiment begonnen hat, wurde zu einer Geschäftsidee. Was in
    eigener Küche oder im Hinterhof geschah, verlagerte sich in ein professionelles Umfeld.
    Tofu ist für uns ein Handwerk.
    Unser Credo lautet – die ganze Bohne! Wir wollen die ganze Sojabohne verwenden. Aus
    Sojatrester, in Japan Okara genannt, stellen wir Okarabällchen her.
    Unsere Okarabällchen gibt‘s in verschiedenen Variationen und sie sind ein Superfood & Snack
    gleichzeitig!</p>

    <p>Unser Kollektiv besteht aus Künstlern, Köchen, Philosophen, Programmierern, Träumern und
    Unternehmern. Wir befinden uns gerade in einem Transformationsprozess, denn wir sind eine
    Genossenschaft in Gründung.</p>,
                <button>Mehr Infos</button>
              </ModalContent>
              <CloseModalButton
                aria-label='Close modal'
                onClick={() => setShowModal(prev => !prev)}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};
