import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

const Circle = styled.div `
background-color: lightblue;
border-radius: 50%;
grid-column: 2/6;
grid-row: 2/6;
box-shadow: rgba(240, 46, 170, 0.4) 0px 5px, rgba(240, 46, 170, 0.3) 0px 10px, rgba(240, 46, 170, 0.2) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px, rgba(240, 46, 170, 0.05) 0px 25px,rgba(0, 0, 0, 0.3) 0px 8px 16px -8px inset;

` 
    
export default Circle
