import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

const Sidepic = styled.div `
background-color: lightblue;
grid-column:1;
grid-row:7 / 12;
display:flex;
flex-flow: column nowrap;
justify-content: space-between;
box-shadow: rgba(240, 46, 170, 0.4) 0px 5px, rgba(240, 46, 170, 0.3) 0px 10px, rgba(240, 46, 170, 0.2) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px, rgba(240, 46, 170, 0.05) 0px 25px;

` 
    
export default Sidepic